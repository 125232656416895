import { DatadogLogger } from '@wbk/logger/react';
import packageJson from './package.json';

export const logger = new DatadogLogger({
  clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN!,
  site: 'datadoghq.com',
  env: process.env.NEXT_PUBLIC_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  version: packageJson.version,
  sessionSampleRate: process.env.NEXT_PUBLIC_ENV === 'production' ? 20 : 100,
  forwardErrorsToLogs: false,
  service: 'webook-browser',
});
