import { useMemo } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Button } from '@wbk/ui';
import LanguageSwitch from '@/components/common/LanguageSwitch';
import { HEADER_NAV } from './nav';
import UserProfileButton from './UserProfileButton';

type Props = {
  settings: LayoutProps['header'];
};

const DesktopHeader = ({ settings }: Props) => {
  const { t } = useTranslation();
  const { locale, pathname, back } = useRouter();

  const menu = useMemo(() => HEADER_NAV.filter((n) => n.title !== 'support_privacy'), []);

  if (settings?.type === 'mini') {
    return (
      <nav className='container hidden grid-cols-3 items-center p-4 lg:grid'>
        <div>
          <Button
            shape='text'
            className='flex items-center gap-1 !py-1 text-sm text-white md:text-base ltr:pl-1 rtl:pr-1'
            onClick={back}
            data-testid='mini-nav-back'
          >
            <Image
              className='ltr:rotate-180'
              src='/icons/common/arrow-white.svg'
              width={20}
              height={20}
              alt={t('common:back')}
            />
            <span>{t('common:back')}</span>
          </Button>
        </div>

        <div>
          <Link
            href='/'
            className='logo-image relative mx-auto block h-7 w-32 shrink-0 text-center hover:opacity-80 xl:h-9 xl:w-40 rtl:h-9 rtl:w-32'
            data-location='header'
            role='menuitem'
          >
            <Image
              className='rtl:hidden'
              src='/icons/logo-en.svg'
              fill
              sizes='163px'
              alt={t('common:seo.title')}
            />
            <Image
              className='ltr:hidden'
              src='/icons/logo-ar.svg'
              fill
              sizes='163px'
              alt={t('common:seo.title')}
            />
          </Link>
        </div>
        <div className='text-end'>
          <LanguageSwitch />
        </div>
      </nav>
    );
  }

  return (
    <nav className='container hidden items-center justify-between p-4 lg:flex'>
      <ul
        className='flex items-center gap-4 text-sm uppercase xl:gap-6 xl:text-base rtl:text-xs xl:rtl:text-base'
        role='menu'
      >
        <li role='presentation'>
          <Link
            href='/'
            className='logo-image relative block h-7 w-32 shrink-0 hover:opacity-80 xl:h-9 xl:w-40 rtl:h-9 rtl:w-32'
            data-location='header'
            role='menuitem'
          >
            <Image
              className='rtl:hidden'
              src='/icons/logo-en.svg'
              fill
              sizes='163px'
              alt={t('common:seo.title')}
            />
            <Image
              className='ltr:hidden'
              src='/icons/logo-ar.svg'
              fill
              sizes='163px'
              alt={t('common:seo.title')}
            />
          </Link>
        </li>
        {settings?.type === 'main' &&
          menu.map((link) => {
            const path = link[`href_${locale}` as 'href_en'] || link.href_en;
            return (
              <li key={path} role='presentation'>
                <Link
                  href={link.external ? path : link.href_en}
                  className={`flex items-center gap-2 whitespace-nowrap border-b-2 border-primary p-1 transition hover:border-primary hover:text-primary focus:border-primary focus:text-primary focus:outline-none ${pathname === path ? 'text-primary' : 'border-transparent'}`}
                  data-title={link.title}
                  data-location='header'
                  role='menuitem'
                >
                  {t(`common:nav.${link.title}`)}
                </Link>
              </li>
            );
          })}
      </ul>

      <ul className='flex gap-1 text-sm xl:gap-4' role='menu'>
        <li className='[&>a]:text-sm [&>a]:xl:text-sm' role='presentation'>
          <LanguageSwitch />
        </li>

        {['main'].includes(String(settings?.type)) && (
          <li className='relative shrink-0 text-sm' role='presentation'>
            <UserProfileButton />
          </li>
        )}
      </ul>
    </nav>
  );
};

export default DesktopHeader;
